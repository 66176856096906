import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import WhyCartusLanding from '../components/WhyCartusLanding';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
import DialogPopupContext from '../utils/dialogpopup-context';
//import LoginPage from '../pages/login';
import CategorySocialMedia from '../components/CategorySocialMedia';

export default function MultiResourcePage({ location,pageContext, data }) {
  const path = data?.contentfulMultiResourcePage?.slug;
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };
  const [open, setOpen] = useState(false);
  const openPopupClick = () => {
    setOpen(true);
  }
  const closePopupClick = () => {
    setOpen(false);
  }
  return (
    <>
      <DialogPopupContext.Provider value={{ status: open, openPopup: openPopupClick, closePopup: closePopupClick}}>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulMultiResourcePage?.seoEntry}
          siteurl={pageContext?.slug}
          bucketName={pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          location={location}
          videoSEOSchema ={data?.contentfulMultiResourcePage?.introSection?.videoComponent?.videoComponent}
        />
        <WhyCartusLanding
          data={data?.contentfulMultiResourcePage}
          basePath={pageContext.basePath}
          backgroundImg={data?.contentfulComponentBackgroundImages}
        />
        <CategorySocialMedia
            data={data?.contentfulMultiResourcePage}
            bucketname={pageContext?.bucketName}
            type={process.env.GATSBY_CARTUS_RESEARCH}
            path={path}
            wordCount={''}
            title={data?.contentfulMultiResourcePage?.categoryTitle}
            landingUrl={path}
            basePath={pageContext.basePath}
            images={data?.contentfulComponentBackgroundImages}
            location={location}
          />
      </Layout>
      </DialogPopupContext.Provider>
    </>
  );
}
export const query = graphql`
  query MultiResourceQuery($contentful_id: String, $locale: String) {
    contentfulComponentBackgroundImages {
      gridBrightBlueBackgroundImage {
        file {
          url
        }
      }
      donutSliderBackgroundImage {
        file {
          url
        }
      }
      headerDonutImage {
        file {
          url
        }
      }
      hoverLeftArrowImage {
        file {
          url
        }
      }
      hoverRightArrowImage {
        file {
          url
        }
      }
      leftArrowImage {
        file {
          url
        }
      }
      rightArrowImage {
        file {
          url
        }
      }
      capsulesBackgroundImage{
        file{
          url
        }
      }
      headerDotMatrixImage{
        file{
          url
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      leftDonutBackgroundImage {
        file {
          url
        }
      }
      linkedlnIcon {
        description
        file {
          url
        }
      }
      twitterIcon {
        description
        file {
          url
        }
      }
      emailIcon {
        description
        file {
          url
        }
      }
      facebookIcon {
        description
        file {
          url
        }
      }
    }
    contentfulMultiResourcePage(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      employeeTestimonialsAutoRotate
      ourWinningMomentsAutoRotate
      proudAssociationLogosAutoRotate
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          file {
            url
          }
        }
      }
      bodyCopy
      dotMatrixBackgroundImage {
        description
        file {
          url
          fileName
        }
      }
      smallDotMatrixBackgroundImage {
        description
        file {
          fileName
          url
        }
      }
      heroImageOrBanner
      heroImage {
        description
        file {
          url
          fileName
        }
      }
      headerSectionTitle
      headerSectionDescription {
        raw
      }
      headerSectionBackgroundImage {
        description
        file {
          url
          fileName
        }
      }
      heroBannerImage{
        description
        file {
          url
          fileName
        }
      }
      heroBannerSection {
        subHeader
        shortDescription {
          raw
        }
        header
        image {
          description
          file {
            url
          }
        }
        ctaOrPardotForm
        ctaButton {
          link {
            label
            link {
              ... on ContentfulInternalLink {
                slug
              }
              ... on ContentfulWebLink {
                url
              }
            }
          }
        }
      }
      introSectionTopic
      introSectionSubTopic
      introSection {
        header
        subHeader
        shortDescription {
          raw
        }
        image {
          description
          file {
            url
          }
        }
        ctaOrPardotForm
        ctaButton {
          link {
            label
            link {
              ... on ContentfulWebLink {
                url
              }
              ... on ContentfulInternalLink {
                slug
              }
            }
          }
        }
        isVideo
        videoComponent {
          videoComponent {
            entryTitle
            videoType
            videoId
            videoTitle
            dateOfPublishing(formatString: "YYYY-MM-DD")
            seoEntry {
              metaDescription {
                metaDescription
              }
            }
          }
        }
      }
      leftDonutBackgroundImage {
        description
        file {
          url
          fileName
        }
      }
      rightCapsuleBackgroundImage{
        description
        file {
          url
          fileName
        }
      }
      additionImage {
        description
        file {
          url
          fileName
        }
      }
      ourProudAssociationHeading
      proudAssosciationTitle
      proudAssosciationSubTitle
      employeeTestimonials {
        authorImage {
          description
          file {
            url
          }
        }
        authorName
        authorDesignation
        authorDescription {
          raw
        }
        secondaryImage {
          description
          file {
            url
          }
        }
      }
      proudAssociationLogos {
        description
        file {
          url
        }
      }
      ourKeyDifferentiatorsHeading
      ourKeyDifferentiatorsMenuTabSection {
        tabTitle
        description {
          raw
        }
        ctaButtonLinkOrPardotForm
        ctaButtonLink {
          label
          link {
            ... on ContentfulInternalLink {
              slug
            }
            ... on ContentfulWebLink {
              url
            }
          }
        }
        pdfImage {
          description
          file {
            url
          }
        }
        uploadPdfOrPardotForm  
        uploadPdf {
          description
          file {
            url
          }
        }
        download
      }
      capsulesBackgroundImage {
        description
        file {
          url
        }
      }
      arrowImage {
        description
        file {
          url
        }
      }
      ourWinningMomentsSectionHeading
      ourWinningMomentsSectionSubHeading
      ourWinningMomentsSectionAwards {
        description
        file {
          url
        }
        description
      }
      joinOurTeamSection {
        header
        subHeader
        shortDescription {
          raw
        }
        mainImage {
          description
          title
          file {
            fileName
            url
          }
        }
        additionalImage {
          description
          title
          file {
            fileName
            url
          }
        }
        ctaOrPardotForm
        ctaButton {
          displayPrimaryCta
          labelForPrimaryCta
          callToAction {
            ... on ContentfulInternalLink {
              id
              slug
              pageName
            }
          }
        }
      }
      ourKeyDifferentiatorsBackgroundImage {
        description
        file {
          url
        }
      }
      pardotForm {
        id
        contentful_id
        entryTitle
        header
        subHeader {
          raw
        }
        iFrameLink
        closeIcon {
          description
          file {
            url
          }
        }
      }
      slug
      featuredInResourceHub
      thumbnailImage {
        description
        file {
          url
        }
      }
      shortDescription {
        raw
      }
      resourceType
      dateOfPublishing
      categoryTitle
      tagsTitle
      categoryList {
        categoryName
        contentful_id
        entryTitle
      }
      tagList {
        contentful_id
        entryTitle
        tagName
      }
      region
      pageVisibility
    }
  }
`;
